import React, { useState, useEffect } from 'react';
import HeroImage from './HeroImage/HeroImage';
import About from './About/About';
import ProjectDetails from './ProjectDetails/ProjectDetails';
import Contact from './Contact/Contact';
import Footer from './Footer/Footer';

import { PortfolioProvider } from '../context/context';

import { contactData, footerData } from '../mock/data';

function ProjectApp(heroImageData, aboutData, detailData, detailTitleData) {
  const [heroImage, setHeroImage] = useState({});
  const [about, setAbout] = useState({});
  const [detailTitle, setDetailTitle] = useState({});
  const [projects, setProjects] = useState([]);
  const [contact, setContact] = useState({});
  const [footer, setFooter] = useState({});
  const [isDesktop, setIsDesktop] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
    } else {
      setIsDesktop(false);
    }

    setHeroImage({ ...heroImageData });
    setAbout({ ...aboutData });
    setDetailTitle({ ...detailTitleData });
    setProjects([...detailData]);
    setContact({ ...contactData });
    setFooter({ ...footerData });
  }, []);

  return (
    <PortfolioProvider value={{ heroImage, about, detailTitle, projects, contact, footer }}>
      {isDesktop && (
        <span className="project-back-btn">
          {/* eslint-disable jsx-a11y/anchor-is-valid, no-script-url */}
          <a
            rel="noopener noreferrer"
            className="cta-btn cta-btn--hero"
            href="javascript:history.back();"
          >
            &lt; Back
          </a>
        </span>
      )}
      <HeroImage />
      <About />
      <ProjectDetails />
      <Contact />
      <Footer />
    </PortfolioProvider>
  );
}

export default ProjectApp;
