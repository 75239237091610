import uuidv1 from 'uuid/v1';

export const wirelessArcadeButtonData = {
  heroImageData: {
    url: 'wireless-arcade-button-heroimage.jpg',
  },
  aboutData: {
    img: 'wireless-arcade-button.jpg',
    title: 'Wireless Arcade Button',
    paragraphOne:
      'What would the controls be if classic arcade games were projected on any table surface? This was the question that I was asked to answer.',
    paragraphTwo:
      'Because the table was an unknown, I could not design something that would be on the side of the table, like a classic pinball machine. But in order to recreate the same feeling, the button had to be on the side somehow.',
    paragraphThree:
      'The prototype also had to be wireless, to showcase the technical abilities of my former employer.',
  },
  detailData: [
    {
      id: uuidv1(),
      img: 'wireless-arcade-paperPrototype.jpg',
      title: 'Paper prototype',
      info:
        'The first step of the process was to examine how the ergonomics would work for a hand-held tabletop controller. What angles are comfortable? How can the user hold it in place during vigorous playing? Where should the button go so that both large- and small-handed users can use it?',
      info2:
        'The office I was working from did not have many materials available, but a paper prototype can be made even with standard office supplies. After marking my findings from having many colleagues hold it, I could continue to 3D modelling and printing.',
    },
    {
      id: uuidv1(),
      img: 'wireless-arcade-handTest.jpg',
      title: 'Ergonomics',
      info:
        'Two printed prototypes later the final shape has been selected. The thumbrest from the paper prototype was dropped in favour of a slimmer design that can be easily held, both by small and large hands. The button is easily reachable for a wide variety of users.',
      info2:
        "The top of the model has been sanded down, making the grip comfortable to hold and giving the end result a subtle two-tone look. The varying width and curve along the length of the device leave room for the user's palm regardless of it's size.",
    },
    {
      id: uuidv1(),
      img: 'wireless-arcade-internals2.jpg',
      title: 'Electronics',
      info:
        'The inner workings of the button center around an ESP32-WROOM-32 board. This board was selected because of its low power consumption, easy reprogramming, little need for surrounding electronics, and support of Bluetooth Low Energy. This latter feature allowed for the device to be low-power and last long on a single charge.',
      info2:
        "Getting the ESP32 to reliably transmit the button state over BLE proved to be a challenge, as the connection was not stable. Simply broadcasting the button state together with the button's ID proved to be a good solution, and tweaking the interval gave good control over power consumption.",
    },
    {
      id: uuidv1(),
      img: 'wireless-arcade-exploded.jpg',
      title: 'Fitting the parts',
      info:
        'To allow non-technical users to easily swap the batteries of the unit, the bottom component has a compartment for an off-the-shelf battery unit, which includes the on/off switch. The other electronics are also mounted to the bottom. The top shell has two slots where M4 nuts can easily be inserted and used as inserts, safely and securely connecting the shell to the bottom.',
      info2:
        'The entire unit can be printed mirrored to create the left-handed version, and will still fit the same components snugly. The transmitted message can be changed by adding a jumper to the circuit board.',
    },
  ],
};

export const giorgioData = {
  heroImageData: {
    url: 'giorgio-behind-laptop.jpg',
  },
  aboutData: {
    img: 'giorgio-infront-laptop.jpg',
    title: 'Giorgio, the robotic musician',
    paragraphOne:
      'All projects start with questions. How can a robot function as a member in a band? Will the audience see it as a person or as a tool? Should it be visible at all?',
    paragraphTwo:
      'While the assignment came with the opportunity to use an off-the-shelf robot, it was designed for pick-and-place work and would not be expressive enough to effectively communicate or deliver a show for the audience. So I designed and built my own robot.',
    paragraphThree:
      'Because my prototype had to function in a live performance, it had to be large enough to be seen, but still portable.',
  },
  detailData: [
    {
      id: uuidv1(),
      img: 'giorgio-infront-closeup.jpg',
      title: 'Robots as musicians',
      info:
        "Musicians use not just instruments, but also computers to take over some of the duties in the performance. The problem is, they can't use both a guitar and a laptop at the same time. What the band I worked with needed was an additional member to operate their music software during the performance.",
      info2:
        'While there are robots that play more classic instruments, this allowed me to focus more on the interactions between musicians than on playing an instrument. This interaction was focused on recreating the body language musicians naturally use to communicate on stage.',
    },
    {
      id: uuidv1(),
      img: 'giorgio-prototypes-2.jpg',
      title: 'Prototypes',
      info:
        "During the development of the concept, I've created multiple iterations of the hardware concept to optimise the movements and look of the prototype. These were all tested with musicians and with their target audience, and each iteration was changed according to the findings from these tests.",
      info2:
        'Some features were added, some were dropped - the camera, for instance, went from a regular camera, to an infra-red camera, to a stylistic element that gives the robot a face.',
    },
    {
      id: uuidv1(),
      img: 'eddie-ifot-live-2.jpg',
      title: 'Live performances',
      info:
        'The final two prototypes were tested during on-stage performances to validate my vision on how they would function in its actual environment, and to see how the audience would react. This included a performance at a technology and music festival, where the audience was questioned afterwards to evaluate how they percieved it.',
      info2:
        'Their reactions were very positive, but the prototype pictured was too small to be properly seen. This prompted the size change going to the final prototype.',
    },
    {
      id: uuidv1(),
      img: 'giorgio-exploded-2.jpg',
      title: 'Inner workings',
      info:
        'The entire body is 3D-printed, except for a sliding bearing that supports the upper body, which is milled from a large diameter PTFE rod. The servo motors are controlled by a built-in Arduino, which receives its commands from a laptop that computes the motions. The power supply for the motors is external to the robot to allow the body to stay sleek and compact.',
      info2:
        'Inside the main moving body, four NeoPixel strips provide illumination from inside the shell. While normally opaque, the 0.8mm thick walls let the light through when lit up and diffuse it.',
    },
    {
      id: uuidv1(),
      img: 'giorgio-litUp-3.jpg',
      title: 'Body language',
      info:
        "The body language used by musicians is mostly body language, but also includes facial movements and mouthing that is hard to reproduce in a robot. Instead, Giorgio's body movements are complemented by the internal lighting. He moves his head to the beat as a human musician would, but counts new parts and changes in by both a colour change and by selectively enabling the LEDs.",
      info2:
        "The movements are designed to give the robot a character similar to how Pixar uses movement to show the internal 'thought process' of its characters. The main function of the camera is to give it a recognisable eye, which makes the feeling of 'eye contact' possible.",
    },
  ],
};

export const skillsData = {
  heroImageData: {
    url: 'azure_spheres.png',
  },
  aboutData: {
    img: 'skills-heroImage-cropped.png',
    title: 'Collection of skills',
    paragraphOne:
      "I've always thought that my ability to learn new things has been my greatest strength. This applies not only to my hobbies, but also to my professional life.",
    paragraphTwo:
      'This has resulted in a very varied set of skills, which has made me a valuable team member for all the people I\'ve worked with, be it a student society, band, or company. This "project" showcases some of those, and gives a little bit of background on them.',
    paragraphThree: ' ', // Space to prevent random ipsum
  },
  detailTitleData: {
      title: ' ',
  },
  detailData: [
    {
      id: uuidv1(),
      img: 'combined-renders-02.png',
      title: '3D modelling and rendering',
      info:
        'Creating 3D models has long been a hobby of mine. Back in 2004, I was one of the founders of L3D, a non-profit dedicated to teaching other highschool students how to create 3D models and online 3D worlds. Then, studying as an Industrial Design Engineer, I learned how to turn my ideas into produceable 3D models.',
      info2:
        "Rendering such models and creating digital artworks every now and then has always been part of this. I've found a combination of 3D animation and rendering to be a very effective way of communicating ideas and concepts, especially in presentations.",
      cta: 'More about L3D',
      url: 'https://www.l3d.nl',
    },
    {
      id: uuidv1(),
      img: 'amped_poster-02.png',
      title: 'Digital illustrations',
      info:
        "As a student of design, it was unavoidable that I picked up Adobe products at one point or another. I've enjoyed the use of them a lot, and used my skills in Illustrator to create poster designs for a local music venue. As with 3D renders, illustrations are great communication tools that I've often used to quickly explain concepts and ideas.",
      info2:
        'Using these skills I have also created a number of flyers and pamphlets showcasing the services of my former employer.',
    },
    {
      id: uuidv1(),
      img: 'innoprint-promo-video-01.png',
      title: 'Video production',
      info:
        'Showcasing interaction concepts using video is a very powerful tool to give prospective users or investors a good overview of the concept. I have created videos for multiple new concepts, and also won a hackathon thanks to the video my team and I created to showcase our concept. For my previous employer I have also written, directed, shot, and edited a number of interview videos for in-house use.',
      info2: '',
    },
    {
      id: uuidv1(),
      img: 'workshop-anonymised-01.png',
      title: 'Hosting workshops',
      info:
        'I like sharing my knowledge, and explaining what I do or how things work to people new to the fields I am in. Hosting workshops is a great format for this, as it allows people to immediately apply their new knowledge and experiment to see for themselves.',
      info2:
        "I've given workshops on 3D modelling, was a teacher's assistant teaching programming and basic electronics using Arduinos, and have developed and hosted a number of workshops on IoT for B2B applications using the Microsoft Azure Sphere for my previous employer.",
    },
    {
      id: uuidv1(),
      img: 'arduino-rev3-01.png',
      title: 'Prototyping boards: Arduino',
      info:
        "Ever since coming into contact with them as a first-year student, I've been using Arduinos to make my projects and prototypes interactive. I've used them to build everything from a quadcopter to interactive robots. The power of this board to me is that it allows me to use fairly simple sensors and actuators, and control them from either the Ardiuno itself or by doing more complex calculations, such as motions and gestures, on a connected laptop.",
      info2:
        "The downside of the (simpler) Arduinos is their connectivity. For this purpose, I've used chipsets such as the ESP8266 or ESP32, and programmed them using the Arduino language and compiler.",
    },
    {
      id: uuidv1(),
      img: 'raspberry-pi-01.png',
      title: 'Prototyping boards: Raspberry Pi',
      info:
        "I love using Raspberry Pi boards for their low-cost computing power. Sometimes an Arduino just doesn't suffice, and you need some extra power. While I much prefer Arduinos for use with sensors and actuators, I have used Raspberry Pi boards for, for instance, hosting a Web platform to control a large number of machines in a laboratory, making automated testing of their software much more powerful and user-friendly than before.",
      info2:
        "I've also worked with similarly powerful boards, such as the Beaglebone Black and, more recently, the Microsoft Azure Sphere.",
    },
    {
      id: uuidv1(),
      img: 'laptop-render-03.png',
      title: 'Programming',
      info:
        "Many problems during prototype development or testing can be solved by using software rather than hardware. I've always believed that selecting the right one for a particular application is the best way to move forward, and have therefore learned how to use both. As mentioned, I've also given workshops on how to use programming languages such as C or Arduino to bring ideas to life.",
      info2:
        'I have programmed browser-based Web applications, Python programs for PC and Raspberry Pi applications, and C and Arduino programs for embedded platforms, to name a few.',
    },
  ],
};
